import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Payment {
  getPaymentReport(
    company_id,
    status,
    created_at_begin,
    created_at_end,
    settlement_of_month_begin,
    settlement_of_month_end
  ) {
    return axios.get(
      `/api/admin/payment/report/company/list`,
      {
        params: {
          company_id: company_id,
          status: status,
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
        },
      },
      config
    );
  }
  getPaymentInfo(id) {
    return axios.get(
      `/api/admin/payment/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPaymentItemDetailByMonth(
    company_id,
    department_id,
    factory_id,
    payment_item_id,
    settlement_of_month_begin,
    settlement_of_month_end,
    payment_item_ids
  ) {
    return axios.get(
      `/api/admin/payment/item/by-month`,
      {
        params: {
          payment_item_ids: payment_item_ids,
          company_id: company_id,
          department_id: department_id,
          factory_id: factory_id,
          payment_item_id: payment_item_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
        },
      },
      config
    );
  }
  getPaymentItemDetail(
    company_id,
    department_id,
    factory_id,
    payment_item_id,
    settlement_of_month_begin,
    settlement_of_month_end
  ) {
    return axios.get(
      `/api/admin/payment/item`,
      {
        params: {
          company_id: company_id,
          department_id: department_id,
          factory_id: factory_id,
          payment_item_id: payment_item_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
        },
      },
      config
    );
  }
  getPaymentSellList(
    id,
    delivery_method,
    payment_method,
    company_id,
    company_account_id,
    factory_id,
    status,
    payment_type_id,
    settlement_of_month_begin,
    settlement_of_month_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/payment/receipt/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          company_account_id: company_account_id,
          factory_id: factory_id,
          status: status,
          payment_type_id: payment_type_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPaymentList(
    id,
    delivery_method,
    payment_method,
    company_id,
    company_account_id,
    factory_id,
    status,
    payment_type_id,
    settlement_of_month_begin,
    settlement_of_month_end,
    apply_employer_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/payment/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          company_account_id: company_account_id,
          factory_id: factory_id,
          status: status,
          payment_type_id: payment_type_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          apply_employer_id: apply_employer_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPaymentListAll(
    id,
    delivery_method,
    payment_method,
    company_id,
    company_account_id,
    factory_id,
    status,
    payment_type_id,
    settlement_of_month_begin,
    settlement_of_month_end,
    apply_employer_id,
    custom_order_number,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/payment/all/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          company_account_id: company_account_id,
          factory_id: factory_id,
          status: status,
          statuses: [12, 5],
          payment_type_id: payment_type_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          apply_employer_id: apply_employer_id,
          custom_order_number: custom_order_number,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchPayment(datas) {
    return axios.post(
      `/api/admin/payment/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addPayment(
    company_id,
    delivery_method,
    payment_method,
    factory_id,
    status,
    payment_type_id,
    pdf_file,
    remark,
    description,
    custom_order_number,
    settlement_of_month,
    pay_date,
    currency_id
  ) {
    return axios.post(
      `/api/admin/payment/`,
      {
        company_id: company_id,
        delivery_method: delivery_method,
        payment_method: payment_method,
        factory_id: factory_id,
        status: status,
        payment_type_id: payment_type_id,
        pdf_file: pdf_file,
        remark: remark,
        description: description,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
        currency_id: currency_id,
        taxed_rate: "0.15",
      },
      config
    );
  }
  editPaymentStatus(id, status) {
    return axios.put(
      `/api/admin/payment/`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  editPaymentFinish(id, status, company_account_id, payment_method) {
    return axios.put(
      `/api/admin/payment/`,
      {
        id: id,
        status: status,
        company_account_id: company_account_id,
        payment_method: payment_method,
      },
      config
    );
  }
  editPaymentFinishAmount(
    id,
    status,
    company_account_id,
    payment_method,
    custom_order_number,
    settlement_of_month,
    pay_date
  ) {
    return axios.put(
      `/api/admin/payment/`,
      {
        id: id,
        status: status,
        company_account_id: company_account_id,
        payment_method: payment_method,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
      },
      config
    );
  }
  editPaymentFinishBatch(
    ids,
    status,
    company_account_id,
    payment_method,
    custom_order_number,
    settlement_of_month,
    pay_date
  ) {
    return axios.put(
      `/api/admin/payment/batch`,
      {
        ids: ids,
        status: status,
        company_account_id: company_account_id,
        payment_method: payment_method,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
      },
      config
    );
  }
  editPayment(
    id,
    company_id,
    delivery_method,
    payment_method,
    factory_id,
    status,
    payment_type_id,
    pdf_file,
    remark,
    description,
    custom_order_number,
    settlement_of_month,
    pay_date,
    currency_id
  ) {
    return axios.put(
      `/api/admin/payment/`,
      {
        id: id,
        company_id: company_id,
        delivery_method: delivery_method,
        payment_method: payment_method,
        factory_id: factory_id,
        status: status,
        payment_type_id: payment_type_id,
        pdf_file: pdf_file,
        remark: remark,
        description: description,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
        currency_id: currency_id,
      },
      config
    );
  }
  deletePayment(id) {
    return axios.delete(
      `/api/admin/payment/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
  EditdPaymentPDF(id, pdf_file) {
    return axios.put(
      `/api/admin/payment/`,
      {
        id: id,
        pdf_file: pdf_file,
      },
      config
    );
  }
  editPaymentRemark(
    id,
    remark,
    description,
    factory_id,
    custom_order_number,
    settlement_of_month,
    currency_id,
    pay_date,
    taxed_rate
  ) {
    return axios.put(
      `/api/admin/payment/`,
      {
        id: id,
        remark: remark,
        description: description,
        factory_id: factory_id,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
        currency_id: currency_id,
        taxed_rate: taxed_rate,
      },
      config
    );
  }
  copyPayment(id, custom_order_number) {
    return axios.post(
      `/api/admin/payment/copy`,
      {
        id: id,
        custom_order_number: custom_order_number,
      },
      config
    );
  }
}
